import Charts from "./highchart";
import "../Style/HomeMobile.css";
import MenuMobile from "./MenuMobile";
const HomeMobile = () => {
  function openInfo() {
    const element = document.getElementById("omveriable");
    if (element.className === "active") {
      element.className = "disactive";
    } else {
      element.className = "active";
    }
  }
  function openMenu() {
    const element = document.getElementById("menu");
    if (element.className === "menuactive") {
      element.className = "menudisactive";
    } else {
      element.className = "menuactive";
    }
  }
  return (
    <>
      <Charts />
      <button id="openInfo" onClick={(e) => openInfo()}>
        Om
      </button>
      <button id="openMenu" onClick={(e) => openMenu()}>
        menu
      </button>

      <div id="omveriable" className="disactive">
        <button id="close" onClick={(e) => openInfo()}>
          close
        </button>
        omveriable
      </div>
      <div id="menu" className="menudisactive">
        <button id="close" onClick={(e) => openMenu()}>
          close
        </button>
        <MenuMobile />
      </div>
    </>
  );
};

export default HomeMobile;
