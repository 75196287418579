import React, { useState, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import indicators_ring from "../API/indicators.json";
import { useLocation } from "react-router-dom";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import drilldown from "highcharts/modules/drilldown.js";
import HighchartsExporting from "highcharts/modules/exporting";
import "../Style/chart.css";
import { BsArrowLeft } from "react-icons/bs";
drilldown(Highcharts);
HighchartsExporting(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


const defaultData = {
  indicator_id: 0,
    indicator_name: "ingen data å vise",
    sub_title: "Vi venter på data fra backend",
    origin: "undefined",
    yAxisTitle: "undefined",
    graphdata: {
      type: "undefined",
      series: [],
    }
}

let dynamicOptionsObject = {
  title: "",
  stacking : undefined,
  yAxisTitle : "",
  toolTipSplit : false,

}

export default function AppHighchart() {
  const indicatorUrl = new URLSearchParams(useLocation().search).get("indicator");
  const municipalityUrl = new URLSearchParams(useLocation().search).get("municipality");
  const [isImmutable, ] = useState(true);
  const [data, setData] = useState(defaultData);
  const [dynamicOptions, setDynamicOptions] = useState(dynamicOptionsObject);
  const chartComponent = useRef(null);
  const [level, ] = useState(["event"]);

  useEffect(() => {
    console.log("level: ", level.length)
    if (level.length === 1) {
      console.log("level is empty");
    }
  }, [level]);

  useEffect(() => {
    chartComponent.current.chart.reflow();
    chartComponent.current.chart.drillUp();
    chartComponent.current.chart.drillUp();
    chartComponent.current.chart.drillUp();
    chartComponent.current.chart.redraw();
    chartComponent.current.chart.drillUp();
    chartComponent.current.chart.drillUp();
    chartComponent.current.chart.drillUp();

    var dataFinnes = false;
    //dynamic import of indicators
    let indicators;
    if (municipalityUrl === "1") {
      indicators = indicators_ring;
    }
    if (municipalityUrl === "3") {
      indicators = indicators_ring;
    }

    indicators.indicators_for_all_sites.map((site) => {
      if(site.site_id === municipalityUrl){
          site.indicators.map((indicator) => {
              if(indicator.indicator_id === indicatorUrl){
                  setData(indicator);
                  dataFinnes = true;
                  const optionsObject = {
                    title: indicator.indicator_name,
                    stacking : indicator.graphdata.series[0].stacking,
                    yAxisTitle : indicator.yAxis === undefined ? "" : "",
                    toolTipSplit : indicator.graphdata.series[0].stacking === undefined ? false : true,
                  }
                  setDynamicOptions(optionsObject);
              }
          })
        }
    });

    if (!dataFinnes) {
      setData({
        indicator_id: 0,
        indicator_name: "ingen data å vise",
        sub_title: "Vi venter på data fra backend",
        origin: "undefined",
        graphdata: {
          type: "undefined",
          series: [],
        },
      });
    }

  }, [indicatorUrl, municipalityUrl]);

  const options = {
    title: {
      text: dynamicOptions.title,

      style: {
        fontWeight: "bold",
        color: "#fff",
      },
    },

    subtitle: {
      text: data.sub_title,
      style: {
        color: "#9699a2",
      },
    },
    credits: {
      position: {
        y: -5,
      },
      href: "#",
      text: indicatorUrl === "0" ? "Kilde: USN/ssb/Proff" : "Kilde: " + data.origin,
      style: {
        color: "#fff"
      },
    },

    legend: isMobile
      ? {
          enabled: false,
        }
      : {
          maxHeight: 80,
          enabled: true,
          layout: "horizontal",
          verticalAlign: "top",
          itemMarginTop: 2,
          itemMarginBottom: 2,
          itemStyle: {
            textOverflow: "clip",
            color: "#fff",
            fontWeight: "bold",
          },

          borderWidth: 1,

          borderRadius: 3,

          itemHoverStyle: {
            color: "#ff0000",
          },

          itemHiddenStyle: {
            color: "#9699a2",
          },
        },

    tooltip: {
      // Sort by value, unless indicator is "Samlet vekstfaktor"
      formatter: indicatorUrl !== "0" ? function () {
        const chart = this;
        return `<b>${chart.points[0].key}</b><br />${chart.points
          .sort((pointA, pointB) => pointB.y - pointA.y)
          .map((point) => {
            return `<span style="color: ${point.color}">\u25CF</span> ${point.series.name}: ${point.y.toLocaleString('no')}${dynamicOptions.yAxisTitle}`;
          })
          .join("<br />")}`;
      } : undefined ,
      shared: true,
      split: false,
      outside: false,
      backgroundColor: "#000",
      style: {
        color: "#fff",
      },
      borderColor: "black",
      borderRadius: 5,
    },

    plotOptions: {
      series: {
        borderWidth: 0,
        lineWidth: isMobile ? 1 : 2,
        cursor: 'pointer',
        marker: {
          enabled: true,
          radius: isMobile ? 2 : 3,
          states: {
            hover: {
              enabled: true,
              radius: isMobile ? 3 : 4,
            },
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        symbolStroke: "#fff",
        x: -50,

        theme: {
          fill: "#353535",
        },
      },
    },

    exporting: {
      menuItemDefinitions: {
        downloadCSV: { text: "DOWNLOAD EXCEL (CSV)" },
        downloadXLS: { text: "DOWNLOAD EXCEL (XLS)" },
      },
    },

    chart: {
      type: "line",
      backgroundColor: "#353535",
      zoomType: "x",
      events: {
        drilldown: function (e) {
          this.setTitle({ text: dynamicOptions.title + " - " + e.point.name});

        },
        drillup: function (e) {
          e?.point?.name === undefined ? 
          this.setTitle({ text: dynamicOptions.title}) :
          this.setTitle({ text: dynamicOptions.title + " - " + e.point.name});

        }
      }
    },

    xAxis: {
      id: "xAxis",

      type: "category",

      labels: {
        style: {
          color: "#fff",
        },
      },
      lineColor: "#fff",

      tickColor: "#fff",
      title: {
        text: "",
        style: {
          color: "#fff",
        },
      },
      plotLines: [{
        events: {
          click: function () {
            console.log("x-axis drilldown")
          }
        }
      }]
    },
    yAxis: {
      id: "yAxis",
      opposite: false,
      gridLineWidth: 0.2,
      crosshair: true,
      accessibility: {
        description: data.yAxisTitle ? data.yAxisTitle : "",
        rangeDescription: data.yAxisTitle ? data.yAxisTitle : "",
        enabled:true
      },
      labels: {
        formatter: function () {
          return this.value.toLocaleString("no-NO");
        },
        style: {
          color: "#fff"
        },
      },
      lineColor: "#fff",

      tickColor: "#fff",
      title: {
        text: data.yAxisTitle ? data.yAxisTitle : "",
        style: {
          color: "#fff",
        },
      },
    },
    series: data.graphdata.series,
    drilldown: {
      activeAxisLabelStyle: {
        color: "#add8e6",
      },

      series: data.graphdata.seriesDrilldown,

      breadcrumbs: {
        showFullPath: false,
      },
    },
  };

  return (
    <div style={{position: "relative",height:"100%"}}>
      { indicatorUrl !== "0" ?
      <div style={{position:"absolute", top:"0.5em",left:"0.5em",color:"white",border:"0px solid pink",zIndex:"1",display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}} onClick={() => window.history.back()}>
        <div style={{display:"flex", alignItems:"center",justifyItems:"center"}}>
          <BsArrowLeft style={{height:"20px",width:"75px"}}/>
          <p>Forrige indikator</p>
        </div>
      </div>
      : null }
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        options={options}
        containerProps={{ style: { height: "100%",zIndex: "3" } }}
        immutable={isImmutable}
      />
    </div>
  );
}
