import { useLocation, Link } from "react-router-dom";
import menu from "../API/menu.json";
import "../Style/menuMobile.css";
import usnSVG from "../images/usn.svg";
import rnfSVG from "../images/RNF.svg";
import Sparebank1SVG from "../images/Sparebank1.svg";
import {
  AiTwotonePieChart,
  AiOutlineHeart,
  AiOutlineStop,
} from "react-icons/ai";
import { BiNetworkChart } from "react-icons/bi";
import { BsHouseFill } from "react-icons/bs";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { FiPercent } from "react-icons/fi";
import { GiReceiveMoney, GiRailRoad } from "react-icons/gi";
import { GoLaw } from "react-icons/go";

import {
  IoBusinessOutline,
  IoSchoolSharp,
  IoMap,
  IoBagSharp,
  IoCropOutline,
} from "react-icons/io5";
import {
  MdFamilyRestroom,
  MdWork,
  MdOutlineQueryStats,
  MdOutlineCoronavirus,
  MdMapsHomeWork,
} from "react-icons/md";
import { VscGraphLine } from "react-icons/vsc";

export default function MenuMobile() {
  const municipalityUrl = new URLSearchParams(useLocation().search).get(
    "municipality"
  );




  function searchFunction() {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("searchBar");
    filter = input.value.toUpperCase();
    ul = document.getElementById("searchUl");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (filter === "") {
        li[i].style.display = "none";
      } else {
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    }
  }

  return (
    <>
{" "}
      <header id="menuHeaderMobile">
        <img src={usnSVG} alt="usnSVG" />
        <img src={rnfSVG} alt="rnfSVG" />
        <img src={Sparebank1SVG} alt="Sparebank1SVG" />
        <br />
        <input
          autoComplete="off"
          type="text"
          id="searchBar"
          //this is double => harsher load for site => suggest only keyUp
          onKeyUp={searchFunction}
          onKeyDown={searchFunction}
          placeholder="Search.."
        />
      </header>{" "}
      <main id="menubody">
        {" "}
        {menu.site_menus.map((value) => {
          if (value.site_id.toString() === municipalityUrl) {
            return (
              <div key={value.name}>
                <ul id="searchUl">
                  {value.search_indicators.map((value20) => {
                    return (
                      <li
                        style={{ display: "none" }}
                        key={value20.indicator_id}
                      >
                        <Link
                          to={{
                            pathname: "/",
                            search:
                              "?municipality=" +
                              municipalityUrl +
                              "&indicator=" +
                              value20.indicator_id,
                          }}
                        >
                          {value20.indicator_name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                {value.root_menu_element.map((value2) => {
                  return (
                    <section className="menuRoot" key={value2.root_menu_name}>
                      <div className="categoryName">
                        {value2.root_menu_name}
                      </div>
                      {value2.favorite.map((value3) => {
                        return (
                          <ol
                            className="favoriteElement"
                            key={value3.indicator_name}
                          >
                            <Link
                              to={{
                                pathname: "/",
                                search:
                                  "?municipality=" +
                                  municipalityUrl +
                                  "&indicator=" +
                                  value3.indicator_id,
                              }}
                            >
                              {value3.icon_name === "AiOutlineHeart" ? (
                                <AiOutlineHeart className="favoriteElementLogo" />
                              ) : value3.icon_name === "AiOutlineStop" ? (
                                <AiOutlineStop className="favoriteElementLogo" />
                              ) : value3.icon_name === "BiNetworkChart" ? (
                                <BiNetworkChart className="favoriteElementLogo" />
                              ) : value3.icon_name === "BsHouseFill" ? (
                                <BsHouseFill className="favoriteElementLogo" />
                              ) : value3.icon_name === "FaMoneyCheckAlt" ? (
                                <FaMoneyCheckAlt className="favoriteElementLogo" />
                              ) : value3.icon_name === "FiPercent" ? (
                                <FiPercent className="favoriteElementLogo" />
                              ) : value3.icon_name === "GiReceiveMoney" ? (
                                <GiReceiveMoney className="favoriteElementLogo" />
                              ) : value3.icon_name === "GiRailRoad" ? (
                                <GiRailRoad className="favoriteElementLogo" />
                              ) : value3.icon_name === "GoLaw" ? (
                                <GoLaw className="favoriteElementLogo" />
                              ) : value3.icon_name === "MdMapsHomeWork" ? (
                                <MdMapsHomeWork className="favoriteElementLogo" />
                              ) : value3.icon_name === "IoBusinessOutline" ? (
                                <IoBusinessOutline className="favoriteElementLogo" />
                              ) : value3.icon_name === "IoSchoolSharp" ? (
                                <IoSchoolSharp className="favoriteElementLogo" />
                              ) : value3.icon_name === "IoMap" ? (
                                <IoMap className="favoriteElementLogo" />
                              ) : value3.icon_name === "IoBagSharp" ? (
                                <IoBagSharp className="favoriteElementLogo" />
                              ) : value3.icon_name === "IoCropOutline" ? (
                                <IoCropOutline className="favoriteElementLogo" />
                              ) : value3.icon_name === "MdFamilyRestroom" ? (
                                <MdFamilyRestroom className="favoriteElementLogo" />
                              ) : value3.icon_name === "MdWork" ? (
                                <MdWork className="favoriteElementLogo" />
                              ) : value3.icon_name === "MdOutlineQueryStats" ? (
                                <MdOutlineQueryStats className="favoriteElementLogo" />
                              ) : value3.icon_name ===
                                "MdOutlineCoronavirus" ? (
                                <MdOutlineCoronavirus className="favoriteElementLogo" />
                              ) : value3.icon_name === "VscGraphLine" ? (
                                <VscGraphLine className="favoriteElementLogo" />
                              ) : (
                                <AiTwotonePieChart className="favoriteElementLogo" />
                              )}

                              <div className="favoriteElementLogoText">
                                {value3.indicator_name}
                              </div>
                            </Link>
                          </ol>
                        );
                      })}

                      <article className="menuRootLevel2">
                        <h3 className="categoryHeader">
                          {value2.root_menu_name}
                        </h3>

                        <div className="categoryscroll">
                          {value2.sub_menu_element.map((value4) => {
                            return (
                              <div
                                className="categorybody"
                                key={value4.sub_menu_name}
                              >
                                <div className="categorybodyElement">
                                  <header className="categorybodyElementHeader">
                                    <div>
                                      {value4.icon_name === "AiOutlineHeart" ? (
                                        <AiOutlineHeart className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "AiOutlineStop" ? (
                                        <AiOutlineStop className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "BiNetworkChart" ? (
                                        <BiNetworkChart className="categorybodyIcone" />
                                      ) : value4.icon_name === "BsHouseFill" ? (
                                        <BsHouseFill className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "FaMoneyCheckAlt" ? (
                                        <FaMoneyCheckAlt className="categorybodyIcone" />
                                      ) : value4.icon_name === "FiPercent" ? (
                                        <FiPercent className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "GiReceiveMoney" ? (
                                        <GiReceiveMoney className="categorybodyIcone" />
                                      ) : value4.icon_name === "GiRailRoad" ? (
                                        <GiRailRoad className="categorybodyIcone" />
                                      ) : value4.icon_name === "GoLaw" ? (
                                        <GoLaw className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "MdMapsHomeWork" ? (
                                        <MdMapsHomeWork className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "IoBusinessOutline" ? (
                                        <IoBusinessOutline className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "IoSchoolSharp" ? (
                                        <IoSchoolSharp className="categorybodyIcone" />
                                      ) : value4.icon_name === "IoMap" ? (
                                        <IoMap className="categorybodyIcone" />
                                      ) : value4.icon_name === "IoBagSharp" ? (
                                        <IoBagSharp className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "IoCropOutline" ? (
                                        <IoCropOutline className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "MdFamilyRestroom" ? (
                                        <MdFamilyRestroom className="categorybodyIcone" />
                                      ) : value4.icon_name === "MdWork" ? (
                                        <MdWork className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "MdOutlineQueryStats" ? (
                                        <MdOutlineQueryStats className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "MdOutlineCoronavirus" ? (
                                        <MdOutlineCoronavirus className="categorybodyIcone" />
                                      ) : value4.icon_name ===
                                        "VscGraphLine" ? (
                                        <VscGraphLine className="categorybodyIcone" />
                                      ) : (
                                        <AiTwotonePieChart className="categorybodyIcone" />
                                      )}
                                    </div>
                                    <div className="categorybodyName">
                                      {value4.sub_menu_name}
                                    </div>
                                  </header>
                                  <main className="categorybodyElementMain">
                                    {value4.indicator.map((value5) => {
                                      return (
                                        <div
                                          className="categorybodyIndicator_name"
                                          key={value5.indicator_name}
                                        >
                                          <Link
                                            to={{
                                              pathname: "/",
                                              search:
                                                "?municipality=" +
                                                municipalityUrl +
                                                "&indicator=" +
                                                value5.indicator_id,
                                            }}
                                          >
                                            {value5.indicator_name}
                                          </Link>
                                          {value5.about_indicator !== null ? (
                                            <div className="categorybodyIndicator_nameText">
                                              <p>Om {value5.indicator_name}:</p>
                                              {value5.about_indicator}
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      );
                                    })}
                                  </main>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </article>
                    </section>
                  );
                })}
              </div>
            );
          }
          return "";
        })}
      </main>
    </>
  );
}
