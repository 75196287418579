import React, { useEffect } from 'react';
import "../../Style/home2023.css";
import { useLocation, Link } from "react-router-dom";


import usnSVG from "../../images/usn.svg";
import hadeland_home from "../../images/hadeland_home.png";
import ringerike_home from "../../images/ringerike-2.png";
import { AiOutlineDownCircle } from 'react-icons/ai';
import { AiOutlineHome } from 'react-icons/ai';

const roller = [
    "Alle",
    "Folkevalgt",
    "Offentlig & Akademia",
    "Næringsliv"
];



const Navbar = ( props ) => {

    const [visning,setVisning] = React.useState("Alle");
    const municipalityUrl = new URLSearchParams(useLocation().search).get("municipality");
    const {changeMenuFilter} = props;


    // Get visning from localstorage on load.
    useEffect(() => {
        const localVisning = localStorage.getItem("visning");
        if(localVisning){
            setVisning(localVisning);
            changeMenuFilter(localVisning)
        }
    },[]);

    // Save visning to localstorage on change.
    useEffect(() =>{
        localStorage.setItem("visning",visning);
    },[visning]);

    const handleChangeVisning = ( valgtRolle ) => {
        setVisning(valgtRolle);
        changeMenuFilter(valgtRolle);
    }


    const visninger = roller.map((rolle) => {
        return (
            <li 
            key={rolle} 
            onClick={() => handleChangeVisning(rolle)}
            style={{cursor:"pointer",textDecoration:visning === rolle ? "underline" : "none"}}
            >
                {rolle}
            </li>
        )
    });

    return (
        <nav id="navcontainer" style={{}}>
            <img 
                src={municipalityUrl === "1" ? ringerike_home : hadeland_home} 
                style={{}}
                alt="logo"
            />
            <ul>
                <li>
                    <h3 style={{position:"relative"}}>
                        <Link
                            className=""
                            to={{
                                pathname: "/",
                                // search:
                                // "?municipality=" + municipalityUrl + "&indicator=0",
                            }}
                            style={{textDecoration:"none",color:"white"}}
                        >

                        
                        <AiOutlineHome style={{color:"white",position:"absolute",left:"-23px",top:"5px"}}/>
                        Start
                        </Link>
                    </h3>
                </li>
                <span>
                <span className="">
                <li>
                    {/* <h3 style={{position:"relative"}} title="Velg region">
                        <AiOutlineDatabase style={{color:"white",position:"absolute",left:"-23px",top:"5px"}}/>
                        Regioner 
                    </h3> */}
                    <ul className="dropdown">
                        <li>
                            <Link
                                className=""
                                to={{
                                    pathname: "/",
                                    search:
                                    "?municipality=1&indicator=0",
                                }}
                                style={{textDecoration:"none",color:"black"}}
                            >
                                Ringerike
                            </Link>
                        </li>
                        <li>
                            <Link
                                className=""
                                to={{
                                    pathname: "/",
                                    search:
                                    "?municipality=3&indicator=0",
                                }}
                                style={{textDecoration:"none",color:"black"}}
                            >
                                Hadeland
                            </Link>
                        </li>
                    </ul>
                </li>
                </span>
                </span>
                <span className="right-nav">
                <li>
                    <h3 style={{position:"relative"}} title="Velg rolle">
                        <AiOutlineDownCircle style={{color:"white",position:"absolute",left:"-23px",top:"5px"}}/>
                        {visning === "Alle" ? "Visning" : visning + ""} 
                    </h3>
                    <ul className="dropdown">
                        {visninger}
                    </ul>
                </li>
                </span>
            </ul>
            <a className="USNLogo" href="https://www.usn.no/" target="_blank" rel="noreferrer"> 
                <img src={usnSVG}  alt="usnSVG"/>
            </a>


        </nav>

    );
};

export default Navbar;
