import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import USN_logo from "../images/USN_logo.png";
import heroImage from "../images/hero.jpg";
import menu from "../API/menu.json";
import "../Style/index.css";
import HomeFelles from "./HomeFelles";
import ringerike_bilde from "../images/ringerike-2.png";
import hadeland_banner from "../images/hadeland-banner.jpeg"
import ringerike from "../images/ringerike-1.jpg";
import hadelandCover from "../images/hadeland_home.png"

export default function App() {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<StartSide />} />
        </Routes>
      </BrowserRouter>
    </main>
  );
  function StartSide() {
    const municipalityUrl = new URLSearchParams(useLocation().search).get(
      "municipality"
    );

    //import the theme according to the muni code
    if (municipalityUrl === "3") {
      import("../Style/hadeland_theme.css");
    }
    else if (municipalityUrl === "1") {
      import("../Style/ringerike_theme.css");
    }

    return municipalityUrl !== null ? (
      <HomeFelles />
    ) : (
      <>
        <img id="heroImage" src={heroImage} alt="heroImage" />
        <main>
          <ul className="cards">
            {menu.site_menus.map((value) => {
              let banner =value.site_id === 3 ? hadeland_banner : ringerike_bilde;
              return (
                <li key={value.site_id}>
                  <a
                    className="card"
                    href={"/?municipality=" + value.site_id + "&indicator=0"}
                  >
                    {
                      // ternary operater for images
                    }
                    <img
                      src={value.site_id === "1" ? ringerike : hadeland_banner}
                      className="card__image"
                      alt={value.site_name}
                    />
                    <div className="card__overlay">
                      <div className="card__header">
                        <svg
                          className="card__arc"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path />
                        </svg>
                        {value.site_id === "1" ? (
                          <img className="card__thumb" src={banner} alt="" />
                        ) : (
                          <img className="card__thumb" src={hadelandCover} alt="" />
                        )}
                      </div>
                    </div>
                  </a>
                </li>
              );
            })}
            <li></li> <li></li>
          </ul>
          <ul className="cards" id="USN_logoContener">
            <li>
              <a
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.usn.no/"
              >
                <img src={USN_logo} id="USN_logo" alt="" />
              </a>
            </li>
          </ul>
        </main>
      </>
    );
  }
}
