import React from 'react';
import { useLocation, Link } from "react-router-dom";
import menu from "../API/menu.json";
import Navbar from "../Components/navigation/navbar";
import Menu from "../Components/menu/Menu";
import Charts from "./highchart";
import {AiOutlineMenuFold, AiOutlineEye} from "react-icons/ai";
import { iconSet } from "../images/iconSet.js";
import "../Style/home2023.css";

const HomeRewrite = (props) => {

    const municipalityUrl = new URLSearchParams(useLocation().search).get("municipality");
    const indicatorUrl = new URLSearchParams(useLocation().search).get("indicator");
    const [viewInfobox, setViewInfobox] = React.useState(true);
    const [menuFilter, setMenuFilter] = React.useState("Alle");
    const [showRelated, ] = React.useState(true);

    const changeMenuFilter = (filter) => {
        setMenuFilter(filter);
    }

    const aboutIndicator = menu.site_menus.map((value) => {
        if (value.site_id === municipalityUrl) {
            return value.search_indicators.map((value2) => {

                if (value2.indicator_id === indicatorUrl) {
                    if (value2.description == null) return <p id='aside-information'>Ingen beskrivelse tilgjengelig</p>
                    return <p id='aside-information'>{value2.description}</p>
                } else return null
            })
        } else return null
    })

    const relatedDocuments = menu.site_menus.map((value) => {
        if (value.site_id === municipalityUrl) {
            return value.search_indicators.map((value2) => {
                if (value2.indicator_id === indicatorUrl) {
                    if (value2.indicator_id === "0") {
                        return (
                        <p id='aside-information' style={{color:"white"}}>
                            <Link style={{color:"white"}} to="/files/Vekstbarometer Ringeriksregionen 2024_NETT.pdf" target="_blank" download>Rapport Ringeriksregionen 2024</Link>
                            <br/>
                            <Link style={{color:"white"}} to="/files/Vekstbarometer_2024_saa_24.04.24_Final_.pptx" target="_blank" download>PowerPoint Ringeriksregionen 2024</Link>
                            <br/>
                            <Link style={{color:"white"}} to="/files/Vekstbarometer_Ringeriksregionen_2023_NETT[3479].pdf" target="_blank" download>Rapport Ringeriksregionen 2023</Link>
                            <br/>
                            <a style={{color:"white"}} href="https://publisher.futuriamedia.no/vekstbarometeret/2023/" target="_parent">Rapport Ringeriksregionen 2023 (Bladbar versjon)</a>                                                       
                        </p>)
                    }
                    if (value2.related_documents == null || value2.related_documents == undefined) return <p id='aside-information'>Ingen dokumenter tilgjengelig</p>
                    return value2.related_documents.map((value3) => {
                        return <a id='aside-information' href={value3.url} target="_blank" rel="noreferrer">{value3.title}</a>
                    })
                } else return null
            })
        } else return null
    })

    const relatedIndicators = menu.site_menus.map((value) => {
        if (value.site_id === municipalityUrl) {
            return value.search_indicators.map((value2) => {
                if (value2.indicator_id === indicatorUrl) {
                    return value2.related_indicators.map((value3) => {
                        console.log("ICON:")
                        console.log(value3.icon_name)
                        return (
                        <div style={{border:"0px solid black",marginRight:"30px", justifyContent: "center", alignItems:"center",height:"50%"}}> 
                        <Link
                            to={{
                                    pathname: "/",
                                    search:
                                    "?municipality=" +
                                    municipalityUrl +
                                    "&indicator=" +
                                    value3.indicator_id,
                                }}
                                state={{indicator: value3.indicator_id}}
                                style={{display:"flex",flexDirection:"column",alignItems:"center",color:"white",textDecoration:"none",marginRight:"",marginTop:"-0px"}}
                                    >
                            {iconSet.find((element) => element.key === value3.icon_name) ? 
                                iconSet.find((element) => element.key === value3.icon_name).icon : 
                                iconSet.find((element) => element.key === "IoCropOutline").icon} <p style={{width:"75%"}}>{" " + value3.indicator_name}</p>
                        </Link>
                        </div>)
                    })
                } else return null
            })
        } else return null
    })



    let indicators = {};
    if (municipalityUrl === "1") {
      indicators = import("../API/indicators.json").then((x) => (indicators = x));
    }
    if (municipalityUrl === "3") {
      indicators = import("../API/indicators_hadeland.json").then(
        (x) => (indicators = x)
      );
    }

    

    return (
        <>
        <main id="maincontainer" style={{height:"auto"}}>
            <Navbar changeMenuFilter={changeMenuFilter} />
            <div className="contentWrapper" style={{display:"flex",flexDirection:"row",zIndex:"2"}}>
                <Menu municipalityUrl={municipalityUrl} dimensions={props.dimensions} rolle={menuFilter}/>
                <div className="chartcontainer" style={{width: viewInfobox ? "85%" : "89%", height:"100%"}}>
                    <section  >
                        <div className="tabscontainer" style={{marginTop:"0em"}}> 
                            {/* <Tabs /> */}
                        </div>
                        <div className="chart" style={{height: showRelated ? props.dimensions.height - 200 : props.dimensions.height - 80,width: "100%",border:"0px solid pink"}}>
                            <Charts />
                        </div>
                        <div id="relatedindicators" style={{opacity: showRelated ? 1 : 0}} >
                            
                            <h3>Relaterte Indikatorer</h3>
                            <div style={{color:"white",display:"flex",flexDirection:"row"}}>
                                {relatedIndicators}
                            </div>
                        </div>
                    </section>
                </div>
                <aside className="right-info-wrapper"style={{display: viewInfobox ? "" : "none",transition:"all 0.3s ease"}} >
                    <h3 className="infoboxheading" style={{textAlign:"center",marginTop:"0.5em"}}>Informasjon</h3>
                    <span style={{position:"relative"}} >
                    <AiOutlineEye className="toggleInfoBox" style={{fontSize:"2em",position:"absolute",top:"-1.5em",right:"0"}}  onClick={() => setViewInfobox(!viewInfobox)}/>
                    <div style={{flexDirection: "column",justifyContent:"space-evenly",height:"100%"}}>
                        <section style={{}}>
                            <h3>Om Indikatoren</h3>
                            <p style={{margin:"9px",fontSize:"12px", borderBottom:"1px solid var(--nav-bg-color"}}>
                                {aboutIndicator}
                            </p>
                        </section>
                        <section style={{}}>
                            <h3>Relaterte dokumenter</h3>
                            <p style={{margin:"9px", borderBottom:"1px solid var(--nav-bg-color"}}>
                                {relatedDocuments}
                        </p>
                        </section>
                    </div>
                    </span>
                </aside>
            { viewInfobox !== true ?
            <span className="expandinfobox" title="Hvis informasjon" style={{margin: "auto",cursor:"pointer",position:"relative"}} onClick={() => setViewInfobox(true)}>
                <AiOutlineMenuFold 
                    style={{fontSize:"4em",color:"white",margin: "auto"}}
                />
            </span> : null}
        </div>
        </main>
        </>
    );
};

export default HomeRewrite;