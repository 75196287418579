import { AiTwotonePieChart } from "react-icons/ai";
import { Link } from "react-router-dom";
import PopOutCategory from "./PopOutCategory";
import { iconSet } from "../../images/iconSet";

const MenuCategory = ({ data, municipalityUrl, rolle }) => {

  // console.log("MENUCATOGRY")
  // console.log(data)

  return (
    <section className="menuRoot" key={data.root_menu_name}>
      <div className="categoryName">{data.root_menu_name}</div>
      {data.favorite.map((value3) => {
        let foundIcon = iconSet.find((el) => el.key === value3.icon_name);

        return (
          <ol
            className="favoriteElement"
            key={`${value3.indicator_name}categoryName`}
          >
            <Link
              to={{
                pathname: "/",
                search:
                  "?municipality=" +
                  municipalityUrl +
                  "&indicator=" +
                  value3.indicator_id,
              }}
            >
              {foundIcon && foundIcon !== -1 ? (
                foundIcon.icon
              ) : (
                <AiTwotonePieChart className="favoriteElementLogo" />
              )}
              <div className="favoriteElementLogoText">
                {value3.indicator_name}
              </div>
            </Link>
          </ol>
        );
      })}

      <article className="menuRootLevel2" style={{overflow: "scroll"}}>
        <h3 className="categoryHeader" style={{textAlign:"center"}}>{data.root_menu_name}</h3>
        {data.sub_menu_element.map((val) => {
          return (
          <PopOutCategory
            key={val.sub_menu_name}
            municipalityUrl={municipalityUrl}
            data={val}
            rolle={rolle}
          />
        )})}
      </article>
    </section>
  );
};
export default MenuCategory;
